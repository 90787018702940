import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Main from './Main'
import LoadingBar from '../LoadingBar'
import Spinner from '../Spinner'
import ErrorBoundary from 'app/theme/ErrorBoundary'
import Navbar from './Navbar'

export default function Layout(props) {
  const { layoutProps, isLoading, ...mainProps } = props

  return (
    <div
      {...layoutProps}
      className={[
        layoutProps?.className,
        'min-h-screen flex flex-col'
      ]}
      >
      <Header />
      {isLoading ? (
        <Main>
          <Spinner className="mx-auto mt-10" />
        </Main>
      ) : (
        <Main {...mainProps}>
          <ErrorBoundary>
            {props.children}
          </ErrorBoundary>
        </Main>
      )}
      <Footer />
    </div>
  )
}
