import React, { useState } from 'react'
import { Search, X } from 'react-feather'

export default function SearchInput(props) {
  const {
    className,
    divClassName,
    defaultValue,
    placeholder = 'Search',
    onChange = () => {},
  } = props

  const [value, setValue] = useState(props.defaultValue)

  return (
    <div className={['relative flex items-center', divClassName]}>
      <Search
        size={16}
        className="absolute left-0 ml-2 text-gray-500"
      />
      <input
        type="text"
        placeholder={placeholder}
        value={value || ''}
        onKeyDown={() => {
          if (['Enter', 'Tab'].includes(event.key)) {
            onChange(value)
          }
        }}
        onChange={event => {
          setValue(event.target.value || null)
        }}
        className={[
          className,
          'input pl-8 pr-10 py-1 border-gray-300 shadow-none',
        ]}
      />
      {value && (
        <button
          type="button"
          children={<X size={12} />}
          onClick={() => {
            setValue('')
            onChange('')
          }}
          className={[
            'rounded bg-gray-200 absolute right-0 p-1 mr-3 rounded-full',
            'hover:bg-gray-300 focus:outline-none',
          ]}
        />
      )}
    </div>
  )
}
