import React from 'react'

export default function LabeledText(props) {
  const { label, labelClassName, placeholder, text, textClassName, children, ...rest } = props

  return (
    <div {...rest}>
      <div className={["label", labelClassName ? labelClassName : 'text-sm']}>
        {label}
      </div>
      <div className={["text-gray-800 font-light", textClassName ? textClassName : "leading-tight"]}>
        {text || children || (
          <span className="text-gray-500">
            {placeholder}
          </span>
        )}
      </div>
    </div>
  )
}
