export default {
  fields: {
    country_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
    caterer_count: {
      readOnly: true
    }
  },
  relationships: {
    country: 'countries',
  }
}
