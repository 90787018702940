import React from 'react'

export default function Form(props) {
  const onSubmit = event => {
    event.preventDefault()
    props.onSubmit && props.onSubmit(event)
  }

  return (
    <form
      {...props}
      onSubmit={onSubmit}
    />
  )
}
