import { format, parseISO } from 'date-fns'

export default {
  fields: {
    caterer_id: {
      readOnly: true,
    },
    contact_id: {
      readOnly: true,
    },
    occasion_id: {
      readOnly: true,
    },
    token: {
      readOnly: true,
    },
    experience_manager_id: {
      readOnly: true,
    },
    account_id: {
      readOnly: true,
    },
    start_date: {
      resolve: date => (
        date ? parseISO(date) : undefined
      ),
      serialize: date => (
        date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
      )
    },
    end_date: {
      resolve: date => (
        date ? parseISO(date) : undefined
      ),
      serialize: (date, attrs) => (
        date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
      )
    },
    verified_at: {
      type: 'date',
      readOnly: true,
    },
    tags: {
      readOnly: true,
    },
    instant_book: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    contact: 'contacts',
    experience_manager: 'users',
    occasion: 'occasions',
  },
}
