import React from 'react'

export default function LabeledLink({name, value}) {
  let url
  try {
    // Test that the link is valid (has a valid protocol)
    url = new URL(value)
  } catch (_) {
    // eat the error; non-op
  }

  return (
    <div>
      <label className="label text-sm">{name}</label>
      <div>
        {url ? (
          // the link is valid! (has a protocol). Display a link using the original value
          <a className="text-blue underline font-light break-all" href={value} target="_blank">{value}</a>
        ) : value ? (
          // the link is technically invalid; show the link as text
          <p className="text-gray-800 font-light leading-tight">{value}</p>
        ) : (
          // show that no value was entered by the user
          <p className="text-gray-800 font-light leading-tight">No value provided</p>
        )}
      </div>
    </div>
  )
}