export default {
  fields: {
    country_id: {
      readOnly: true,
    },
    region_id: {
      readOnly: true,
    },
    parent_id: {
      readOnly: true,
    },
    market_city_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    country: 'countries',
    region: 'regions',
    parent: 'cities',
    market_city: 'cities',
  }
}
