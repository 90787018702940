export default {
  fields: {
    recipient_id: {
      readOnly: true,
    },
    sender_id: {
      readOnly: true,
    },
    lead_id: {
      readOnly: true,
    },
    proposal_id: {
      readOnly: true,
    },
    event_id: {
      readOnly: true,
    },
    booking_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    sender: {
      polymorphic: true,
    },
    recipients: {
      type: 'message_recipients',
    },
    proposal: {
      type: 'proposals',
    },
    lead: {
      type: 'leads',
    },
    event: {
      type: 'events',
    },
    booking: {
      type: 'bookings',
    }
  },
}
