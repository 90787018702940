import React, { createContext, useContext } from 'react'
import useDropDown from './useDropDown'
import styles from './Dropdown.module.css'

const context = createContext({})

export default function Dropdown(props) {
  const ctx = useDropDown()

  return (
    <context.Provider value={ctx}>
      <div
        {...props}
        className={[
          props.className,
          'relative',
        ]}
      />
    </context.Provider>
  )
}

Dropdown.Toggle = props => {
  const {
    as: Type = 'button',
    children,
    onClick,
    className,
    toggledClassName,
    ...rest
  } = props

  const { setOpen, isOpen } = useContext(context)

  const handleClick = event => {
    if (onClick) {
      onClick(event, setOpen)
    }
    if (!event.defaultPrevented) {
      setOpen(o => !o)
    }
  }

  let element = children
  if (typeof element === 'function') {
    element = element({ isOpen: isOpen })
  }

  return (
    <Type
      {...rest}
      onClick={handleClick}
      children={element}
      className={[
        props.className,
        isOpen && toggledClassName,
      ]}
    />
  )
}

Dropdown.Content = props => {
  const { ref, isOpen } = useContext(context)

  return (
    <div
      ref={ref}
      {...props}
      className={[
        props.className,
        styles.content,
        isOpen && styles.open,
        'absolute z-10 min-w-full w-auto whitespace-no-wrap overflow-auto',
      ]}
    />
  )
}

Dropdown.Item = props => {
  const {
    as: Type = 'button',
    onClick,
    ...rest
  } = props

  const { setOpen } = useContext(context)

  const handleClick = event => {
    if (onClick) {
      onClick(event, setOpen)
    }
    if (!event.defaultPrevented) {
      setOpen(o => !o)
    }
  }

  return (
    <Type
      {...rest}
      onClick={handleClick}
    />
  )
}
