import { useState, useEffect, useMemo } from 'react'
import { useClient } from 'jsonapi-react'
import useLoginToken from './useLoginToken'

export default function useCreateSession() {
  const client = useClient()
  const [token, setToken, removeToken] = useLoginToken()

  const [state, setState] = useState({
    user: null,
    isLoading: true,
    isLoggedIn: false,
  })

  const destroy = () => {
    client.removeHeader('Authorization')

    removeToken()

    setState({
      user: null,
      isLoading: false,
      isLoggedIn: false,
    })
  }

  const refetch = () => {
    client.addHeader(
      'Authorization',
      `Bearer ${token}`
    )
    client.fetch(['users/me', {
      include: ['admin'],
      extra_fields: {
        admins: [
          'departments_list'
        ]
      }
    }]).then(result => {
      if (result.error) {
        client.removeHeader('Authorization')

        setState({
          ...state,
          isLoading: false,
          isLoggedIn: false,
        })
      } else {
        setState({
          user: result.data,
          isLoading: false,
          isLoggedIn: true,
        })
      }
    })
  }

  useEffect(() => {
    if (token) {
      refetch()
    } else if (state.user) {
      setState({
        ...state,
        isLoggedIn: false,
      })
    } else {
      setTimeout(() => setState({
        isLoading: false
      }), 600)
    }
  }, [token])

  useEffect(() => {
    return client.subscribe(action => {
      if (action.error?.status === '401') {
        destroy()
      }
    })
  }, [])

  return useMemo(() => ({
    ...state,
    refetch,
    token,
    setToken,
    destroy,
  }), [state])
}
