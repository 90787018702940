export default {
  fields: {
    user_id: {
      readOnly: true,
    },
    department_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    user: 'users',
    departments: 'departments',
    permissions: 'permissions'
  }
}
