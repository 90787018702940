import React from 'react'

export default function ButtonBar({ children, ...rest }) {
  if (!Array.isArray(children)) {
    children = [children]
  }

  return (
    <div {...rest} className={[
      'flex rounded bg-white border border-gray-300 text-gray-600',
      rest.className,
    ]}>
      {children.map((child, index) => (
        <div
          key={index}
          children={child}
          className={[
            'flex hover:bg-gray-100',
            index > 0 && 'border-l border-gray-300',
            index === 0 && 'rounded-l',
            index === children.length - 1 && 'rounded-r',
          ]}
        />
      ))}
    </div>
  )
}
