import config from './config'

export default {
  fields: {
    caterer_id: {
      readOnly: true,
    },
    type_label: {
      readOnly: true,
      resolve: (_, attrs) => (
        attrs.type ? (config.types[attrs.type] || null) : undefined
      )
    },
    deletable: {
      type: 'boolean',
      readOnly: true
    },
    reasons_not_deletable: {
      type: 'object',
      readOnly: true
    },
    swappable: {
      type: 'boolean',
      readOnly: true
    },
    swappable_caterer_services: {
      type: 'object',
      readOnly: true
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    caterer: 'caterers',
  }
}
