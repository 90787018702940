import React from 'react'
import { useSession } from 'app/session'
import logo from 'assets/svg/logo-with-circle.svg'
import { format } from 'date-fns'
import env from 'env'

export default function Footer() {
  const session = useSession()

  return (
    <footer className="bg-white py-12 border-t border-gray-200">
      <div className="container">
        <img src={logo} width={50} className="m-auto" />
        <div className="text-center text-xs text-gray-700 mt-2 font-light">
          <div>&copy; {format(new Date(), 'yyyy')} Roaming Hunger</div>
          <div>Version: {env.APP_VERSION || 'n/a'}</div>
        </div>
      </div>
    </footer>
  )
}
