export default {
  fields: {
    experience_manager_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    contacts: {
      type: 'contacts'
    },
    experience_manager: {
      type: 'users'
    },
    proposals: {
      type: 'proposals',
    },
    notes: {
      type: 'notes',
    },
    locations: {
      type: 'locations',
    },
  }
}
