import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  isValidElement,
} from 'react'

import { createPortal } from 'react-dom'

const ESC_KEY = 27

export function useModalState(openInitally = false) {
  const [isOpen, setOpen] = useState(openInitally)

  const openModal = useCallback(() => {
    setOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setOpen(false)
  }, [])

  const toggleModal = useCallback(() => {
    setOpen(open => !open)
  }, [])

  return {
    isOpen,
    openModal,
    closeModal,
    toggleModal,
  }
}

export function useModal(Component, defaultProps) {
  const state = useModalState()

  const Modal = useCallback(
    props => (
      <Component
        isOpen={state.isOpen}
        closeModal={state.closeModal}
        {...defaultProps}
        {...props}
      />
    ),
    [state.isOpen]
  )

  return {
    Modal,
    ...state,
  }
}

export function Modal(props) {
  let {
    isOpen = false,
    closeModal = () => {},
    overlay = true,
    closeOnOutsideClick = true,
    closeOnEsc = true,
    container: Container = ModalContainer,
    rootSelector,
    ...rest
  } = props

  const innerRef = useRef()
  const closeRef = useRef(false)

  const [root, setRoot] = useState()
  const [isVisible, setIsVisible] = useState(false)

  const onClick = () => {
    if (!closeRef.current) {
      return
    }

    closeRef.current = false

    if (closeOnOutsideClick) {
      closeModal()
    }
  }

  const onMouseDown = ({ target }) => {
    if (innerRef.current === target) {
      closeRef.current = true
    }
  }

  const onKeyDown = ({ keyCode }) => {
    if (closeOnEsc && keyCode === ESC_KEY) {
      closeModal()
    }
  }

  useEffect(() => {
    setRoot(rootSelector ? document.querySelector(rootSelector) : document.body)
  }, [])

  useEffect(() => {
    if (!isOpen || !root) {
      return
    }

    document.addEventListener('keydown', onKeyDown)
    document.body.style.overflow = 'hidden'

    if (innerRef.current) {
      innerRef.current.addEventListener('mousedown', onMouseDown)
      innerRef.current.addEventListener('click', onClick)
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.body.style.overflow = null

      if (innerRef.current) {
        innerRef.current.removeEventListener('mousedown', onMouseDown)
        innerRef.current.removeEventListener('click', onClick)
      }
    }
  }, [root, isOpen])

  useEffect(() => {
    if (isOpen && !isVisible) {
      setTimeout(() => setIsVisible(true), 50)
    } else if (isVisible && !isOpen) {
      setIsVisible(false)
    }
  }, [isOpen, isVisible])

  if (!root || (!isOpen && !isVisible)) {
    return null
  }

  if (typeof overlay === 'function') {
    overlay = React.createElement(overlay)
  }

  const element = (
    <Container
      isOpen={isOpen}
      isVisible={isVisible}
      innerRef={innerRef}
      overlay={overlay}
      children={<div {...rest} />}
    />
  )

  return createPortal(element, root)
}

export function ModalContainer(props) {
  const { innerRef, isVisible, overlay, children } = props

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 50,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        display: 'flex',
        transition: 'opacity 50ms',
        ...(isVisible ? {
          opacity: 1,
        } : {
          opacity: 0,
        })
      }}
    >
      {overlay ? (
        isValidElement(overlay) ? (
          overlay
        ) : (
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: '#000',
              opacity: 0.5,
            }}
          />
        )
      ) : null}

      <div
        style={{
          position: 'relative',
          overflow: 'auto',
          minHeight: 0,
          flexGrow: 1,
        }}
      >
        <div
          ref={innerRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            transition: 'transform 50ms',
            ...(isVisible ? {
              transform: 'scale(1)',
            } : {
              transform: 'scale(.95)',
            })
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
