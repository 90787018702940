import React from 'react'

export default function Spinner({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 38 38"
      height="2em"
      stroke="currentColor"
      {...props}
      className={[
        className,
        `text-${color || 'blue'}`,
      ]}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <path d="M36 18c0-9.94-8.06-18-18-18" strokeWidth="3">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.5s"
              repeatCount="indefinite"
            />
          </path>
          <circle cx="36" cy="18" r="1">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.5s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  )
}
