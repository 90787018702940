import React from 'react'
import Paginate from 'react-paginating'

export default function Pagination(props) {
  let {
    total,
    limit,
    current,
    pageCount = 5,
    getLinkProps = () => ({}),
    className,
    ...rest
  } = props

  total = total || 0
  limit = limit ? parseInt(limit, 10) : 20

  const currentPage = current ? parseInt(current, 10) : 1
  const min = currentPage * limit - limit + 1
  const max = Math.min(currentPage * limit, total)

  return (
    <Paginate
      total={total}
      limit={limit}
      pageCount={pageCount}
      currentPage={currentPage}
    >
      {({
        pages,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        totalPages,
        getPageItemProps
      }) => (
        <div className={[
          className,
          'flex justify-between items-center text-gray-600 leading-tight',
          'text-sm',
        ]}>
          {totalPages ? (
            <div>
              Results {min} - {max} out of {total}
            </div>
          ) : null}

          <div className="flex">
            {hasPreviousPage && (
              <a
                key="prev"
                children="Prev"
                className="px-3 py-2 mx-1 shadow bg-white rounded-sm cursor-pointer"
                {...getLinkProps({ page: previousPage, prev: true })}
              />
            )}
            {pages.length > 1 && pages.map(page => (
              <a
                key={page}
                children={page}
                className={[
                  'px-3 py-2 mx-1 shadow rounded-sm cursor-pointer',
                  currentPage === page ? 'bg-primary text-blue' : 'bg-white'
                ]}
                {...getLinkProps({ page: page, current: currentPage === page })}
              />
            ))}
            {hasNextPage && (
              <a
                key="next"
                children="Next"
                className="px-3 py-2 mx-1 shadow bg-white rounded-sm cursor-pointer"
                {...getLinkProps({ page: nextPage, next: true })}
              />
            )}
          </div>
        </div>
      )}
    </Paginate>
  )
}
