import React from 'react'
import loadable, { lazy } from '@loadable/component'
import Layout from './theme/layout'

const ungated = [
  {
    path: '/login',
    component: loadable(() => import('./login/LoginPage')),
  },
  {
    path: '/logout',
    component: loadable(() => import('./login/LogoutPage')),
  },
  {
    path: '/forgot-password',
    component: loadable(() => import('./login/ForgotLoginPage')),
  },
  {
    path: '/reset-password',
    component: loadable(() => import('./login/ResetPasswordPage')),
  },
  {
    path: '/reset-multifactor/:multifactor_reset_token',
    component: loadable(() => import('./login/ResetMultifactorPage')),
  },
  {
    path: '*',
    component: loadable(() => import('./NotFoundPage')),
  },
]

const gated = [
  {
    path: '/',
    component: () => import('./dashboard/DashboardPage'),
  },
  {
    path: ['/accounting', '/accounting/*'],
    component: () => import('./accounting_exports/Routes'),
  },
  {
    path: ['/accounts', '/accounts/*'],
    component: () => import('./accounts/Routes'),
  },
  {
    path: ['/audits', '/audits/*'],
    component: () => import('./audits/AuditsPage'),
  },
  {
    path: ['/bills', '/bills/*'],
    component: () => import('./bills/BillsPage'),
  },
  {
    path: ['/bookings', '/bookings/*'],
    component: () => import('./bookings/Routes'),
  },
  {
    path: ['/booking-requests/*'],
    component: () => import('./booking-requests/Routes'),
  },
  {
    path: ['/caterers', '/caterers/*'],
    component: () => import('./caterers/Routes'),
  },
  {
    path: ['/countries', '/countries/*'],
    component: () => import('./countries/Routes'),
  },
  {
    path: ['/events', '/events/*'],
    component: () => import('./events/Routes'),
  },
  {
    path: ['/promotions', '/promotions/*'],
    component: () => import('./promotions/Routes'),
  },
  {
    path: ['/promotion-surveys', '/promotion-surveys/*'],
    component: () => import('./promotion-surveys/Routes'),
  },
  {
    path: ['/promotion-accounts', '/promotion-accounts/*'],
    component: () => import('./promotion-accounts/Routes'),
  },
  {
    path: ['/leads', '/leads/*'],
    component: () => import('./leads/Routes'),
  },
  {
    path: ['/admins', '/admins/*'],
    component: () => import('./admins/Routes'),
  },
  {
    path: ['/users', '/users/*'],
    component: () => import('./users/Routes'),
  },
  {
    path: ['/settings', '/settings/*'],
    component: () => import('./settings/Routes'),
  },
  {
    path: ['/proposals', '/proposals/*'],
    component: () => import('./proposals/Routes'),
  },
  {
    path: ['/tags', '/tags/*'],
    component: () => import('./tags/Routes'),
  },
  {
    path: ['/good-fors', '/good-fors/*'],
    component: () => import('./good-fors/Routes'),
  },
  {
    path: ['/dietary-tags', '/dietary-tags/*'],
    component: () => import('./dietary-tags/Routes'),
  },
  {
    path: ['/featured-clients', '/featured-clients/*'],
    component: () => import('./featured-clients/Routes'),
  },
  {
    path: ['/feature-releases', '/feature-releases/*'],
    component: () => import('./feature-releases/Routes'),
  },
  {
    path: ['/press-highlights', '/press-highlights/*'],
    component: () => import('./press-highlights/Routes'),
  },
  {
    path: ['/regions', '/regions/*'],
    component: () => import('./regions/Routes'),
  },
  {
    path: ['/cities', '/cities/*'],
    component: () => import('./cities/Routes'),
  },
  {
    path: ['/landmarks', '/landmarks/*'],
    component: () => import('./landmarks/Routes'),
  },
  {
    path: ['/agency-leads', '/agency-leads/*'],
    component: () => import('./agency-leads/Routes'),
  },
  {
    path: ['/case-studies', '/case-studies/*'],
    component: () => import('./case-studies/Routes'),
  },
  {
    path: ['/caterer-submissions', '/caterer-submissions/*'],
    component: () => import('./caterer-submissions/Routes'),
  },
  {
    path: ['/documents', '/documents/*'],
    component: () => import('./documents/Routes'),
  },
  {
    path: ['/territories', '/territories/*'],
    component: () => import('./territories/Routes'),
  },
  {
    path: ['/catering-minimums', '/catering-minimums/*'],
    component: () => import('./catering-minimums/Routes'),
  },
  {
    path: ['/agreements', '/agreements/*'],
    component: () => import('./agreements/Routes'),
  },
  {
    path: ['/occasions', '/occasions/*'],
    component: () => import('./occasions/Routes'),
  },
  {
    path: ['/reports/*'],
    component: () => import('./reports/Routes'),
  },
  {
    path: ['/marketplace', '/marketplace/listings', '/marketplace/listings/*'],
    component: () => import('./market-listings/Routes')
  },
  {
    path: ['/marketplace/leads', '/marketplace/leads/*'],
    component: () => import('./market-leads/Routes')
  },
  {
    path: ['/marketplace/inquiries'],
    component: () => import('./market-inquiries/InquiriesPage')
  },
  {
    path: ['/marketplace/inquiries/:inquiryId'],
    component: () => import('./market-inquiries/InquiryFormPage')
  },
  {
    path: ['/performance'],
    component: () => import('./performance/PerformancePage')
  },
  {
    path: ['/nearest-city'],
    component: () => import('./nearest-city/NearestCityPage')
  },
  {
    path: ['/pages', '/pages/*'],
    component: () => import('./pages/Routes')
  },
  {
    path: ['/reviews', '/reviews/*'],
    component: () => import('./reviews/Routes')
  },
  {
    path: ['/review-tags', '/review-tags/*'],
    component: () => import('./review-tags/Routes')
  },
  {
    path: ['/vendor-invoice/:booking_id'],
    component: () => import('./bookings/VendorInvoicePage')
  },
].map(route => ({
  ...route,
  component: loadable(route.component, {
    fallback: <Layout />,
  }),
}))

export default {
  gated,
  ungated,
}
