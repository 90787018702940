import { format, parseISO } from 'date-fns'

export default {
  fields: {
    proposal_id: {
      readOnly: true,
    },
    user_id: {
      readOnly: true,
    },
    caterer_id: {
      readOnly: true,
    },
    occasion_id: {
      readOnly: true,
    },
    landmark_id: {
      readOnly: true,
    },
    account_id: {
      readOnly: true,
    },
    token: {
      readOnly: true,
    },
    vendor_payout: {
      readOnly: true,
    },
    start_date: {
      resolve: date => (
        date ? parseISO(date) : undefined
      ),
      serialize: date => (
        date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
      )
    },
    end_date: {
      resolve: date => (
        date ? parseISO(date) : undefined
      ),
      serialize: (date, attrs) => (
        date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
      )
    },
    load_start: {
      resolve: date => (
        date ? parseISO(date) : undefined
      ),
      serialize: (date, attrs) => (
        date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
      )
    },
    load_end: {
      resolve: date => (
        date ? parseISO(date) : undefined
      ),
      serialize: (date, attrs) => (
        date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
      )
    },
    tags: {
      readOnly: true,
    },
    total_applicants: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    proposal: {
      type: 'proposals',
    },
    account: {
      type: 'accounts'
    },
    caterer: {
      type: 'caterers',
    },
    occasion: {
      type: 'occasions',
    },
    landmark: {
      type: 'landmarks',
    },
    bookings: {
      type: 'bookings',
    },
    photos: {
      type: 'photos',
    },
  },
}
