import React from 'react'

export default function Separator(props) {
  return (
    <div
      {...props}
      className={[
        'border-t border-gray-300 border-dashed',
        props.className,
      ]}
    />
  )
}
