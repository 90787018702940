import React, { forwardRef } from 'react'

const theme = {
  div: null,
  label: 'label block mb-2',
  textarea: 'textarea',
  error: 'text-sm text-red-400 mt-1',
}

export default forwardRef(function Textarea(props, ref) {
  const {
    value,
    className,
    classNames,
    onChange,
    divProps,
    divClassName,
    label,
    labelClassName,
    labelProps,
    error,
    errorProps,
    ...textareaProps
  } = props

  const styles = { ...theme, ...classNames }

  return (
    <div className={[styles.div, divClassName]} {...divProps}>
      {label && (
        <label className={[styles.label, labelClassName]} {...labelProps}>
          {label}
        </label>
      )}
      <textarea
        ref={ref}
        {...textareaProps}
        className={[styles.textarea, className]}
        value={onChange ? value || '' : value}
        onChange={event => {
          if (onChange) {
            onChange(event.target.value || null, event)
          }
        }}
      />
      {error && (
        <div className={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
})
