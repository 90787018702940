import { format, parseISO } from 'date-fns'

export default {
  fields: {
    caterer_id: {
      readOnly: true,
    },
    caterer_service_id: {
      readOnly: true,
    },
    event_id: {
      readOnly: true,
    },
    booking_id: {
      readOnly: true,
    },
    calendar_id: {
      readOnly: true,
    },
    start_date: {
      resolve: date => (
        date ? parseISO(date) : undefined
      ),
      serialize: date => (
        date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
      )
    },
    end_date: {
      resolve: date => (
        date ? parseISO(date) : undefined
      ),
      serialize: (date, attrs) => (
        date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
      )
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    caterer: 'caterers',
    event: 'events',
    calendar: 'calendars',
    caterer_service: 'caterer_services'
  }
}
