import React from 'react'
import styles from './Menu.module.css'

export default function Menu(props) {
  const { className, children, ...rest } = props

  return (
    <div className={[className, styles.menu, 'card']} {...rest}>
      {children}
    </div>
  )
}
