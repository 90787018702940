import React, { useState } from 'react'
import logo from 'assets/svg/logo-no-circle.svg'
import { useNotify } from 'app/theme'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { useMutation } from 'jsonapi-react'

export default function LoginPage({ session }) {
  const [fields, setFields] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState(null)
  const [submit, { client }] = useMutation('authorizations')

  const handleSubmit = e => {
    e.preventDefault()

    submit({ ...fields, scope: 'admin' }).then(result => {
      if (result.error) {
        alert(result.error.message)
      } else if (result.errors) {
        setErrors(result.errors)
      } else if (result.data.multifactor_token) {
        setFields({
          multifactor_token: result.data.multifactor_token,
          qrcode: result.data.qrcode || undefined,
        })
      } else {
        const expires = new Date(result.data.expires_at)
        session.setToken(result.data.token, { expires })
      }
    })
  }

  if (session.isLoggedIn) {
    return <Redirect to="/" />
  }

  return (
    <div className="min-h-screen flex items-center justify-center px-2">
      <div className="w-full max-w-md">
        <div className="bg-white px-10 py-16 rounded shadow overflow-hidden">
          {fields?.multifactor_token ? (
            <MultifactorTokenForm
              multifactor_token={fields.multifactor_token}
              qrcode={fields?.qrcode ? fields?.qrcode : undefined}
              session={session}
            />
          ) : (
            <form onSubmit={handleSubmit}>
              <h1 className="font-platform text-4xl text-navy text-center leading-tight">
                Welcome!
              </h1>
              <p className="text-center text-gray-800 font-light text-lg mb-8">
                Please sign in to get cookin!
              </p>
              <div className="mb-5">
                <input
                  type="text"
                  placeholder="Email"
                  className="input input-lg w-full"
                  value={fields.email}
                  onChange={e => {
                    setFields({ ...fields, email: e.target.value })
                  }}
                />
                {errors?.email && (
                  <ErrorMessage message={`Email ${errors.email[0].message}`} />
                )}
                {errors?.scope && (
                  <ErrorMessage message="User does not have sufficient privileges." />
                )}
              </div>
              <div className="mb-6">
                <input
                  type="password"
                  placeholder="Password"
                  className="input input-lg w-full"
                  value={fields.password}
                  onChange={e => {
                    setFields({ ...fields, password: e.target.value })
                  }}
                />
                {errors?.password && (
                  <ErrorMessage
                    message={`Password ${errors.password[0].message}`}
                  />
                )}
              </div>
              <button
                type="submit"
                className="btn btn-blue w-full py-3 rounded text-lg mb-8"
                children="Submit"
              />
              <div className="text-center">
                <Link
                  to="/forgot-password"
                  className="font-bold text-blue hover:text-navy"
                >
                  Forgot your password?
                </Link>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

function MultifactorTokenForm(props) {
  const { multifactor_token, qrcode, session } = props

  const [errors, setErrors] = useState(null)
  const [fields, setFields] = useState({
    multifactor_token: multifactor_token,
    multifactor_code: '',
  })

  const [submit, { client }] = useMutation('authorizations')

  const handleSubmit = e => {
    e.preventDefault()

    submit({ ...fields, scope: 'admin' }).then(result => {
      if (result.error) {
        alert(result.error.message)
      } else if (result.errors) {
        setErrors(result.errors)
      } else {
        const expires = new Date(result.data.expires_at)
        session.setToken(result.data.token, { expires })
      }
    })
  }

  if (session.isLoggedIn) {
    return <Redirect to="/" />
  }

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="font-platform text-4xl text-navy text-center leading-tight">
        Enter your code
      </h1>
      <div className="my-6">
        {qrcode && (
          <div className="text-center">
            <img className="my-auto w-full" alt="QR Code" src={qrcode} />
            <p className="leading-tight font-light text-gray-800 text-lg mt-3 mb-8">
              Scan with your authenticator app.
            </p>
          </div>
        )}

        <input
          autoFocus
          type="number"
          placeholder="Code"
          className="input input-lg w-full"
          autocomplete="one-time-code"
          value={fields.multifactor_code}
          onChange={e => {
            setFields({ ...fields, multifactor_code: e.target.value })
          }}
        />
        {errors?.multifactor_code && (
          <ErrorMessage
            message={`Code ${errors.multifactor_code[0].message}`}
          />
        )}
      </div>
      <button
        type="submit"
        className="btn btn-blue w-full py-3 rounded text-lg mb-8"
        children="Submit"
      />
      <div className="text-center">
        {multifactor_token && (
          <ResetMultifactor multifactor_token={multifactor_token} />
        )}
      </div>
    </form>
  )
}

function ResetMultifactor(props) {
  const { multifactor_token } = props

  const [submit, { client }] = useMutation('multifactor_resets')
  const notify = useNotify()
  const history = useHistory()

  const handleReset = e => {
    e.preventDefault()

    submit({ multifactor_token: multifactor_token }).then(res => {
      if (res.data) {
        history.push('/')
        notify.show({
          theme: 'success',
          title: 'Success',
          message: 'Check your email!',
        })
      }
    })
  }

  return (
    <button
      type="button"
      className="font-bold text-blue hover:text-navy"
      onClick={handleReset}
    >
      Reset Multifactor
    </button>
  )
}

function ErrorMessage(props) {
  return <div className="text-red-400 mt-1 ml-1">{props.message}</div>
}
