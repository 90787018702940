import React from 'react'

const theme = {
  error: 'text-sm text-red-400 mt-1',
}

export default function Checkbox(props) {
  const {
    label = null,
    size,
    disabled,
    checked,
    defaultChecked,
    onChange,
    className,
    error,
    errorProps,
    id,
    name,
    ...rest
  } = props

  const styles = { ...theme, ...className }

  return (
    <>
      <label
        className={[
          className,
          'checkbox',
          size && `checkbox-${size}`,
          disabled && 'text-gray-500 cursor-default',
        ]}
      >
        <input
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          onChange={event => {
            onChange && onChange(event.target.checked, event)
          }}
        />
        <span>{label}</span>
      </label>
      {error && <span className={styles.error}>{error}</span>}
    </>
  )
}
