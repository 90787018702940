let env

if (typeof window !== 'undefined') {
  env = {
    ...window.__APP_STATE__.env,
  }
} else {
  env = {
    APP_VERSION: process.env.APP_VERSION,
    API_URL: process.env.API_URL,
    APP_ENV: process.env.APP_ENV,
    GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
    MEDIA_URL: process.env.MEDIA_URL,
    RH_URL: process.env.RH_URL,
    VENDOR_URL: process.env.VENDOR_URL,
    CATERING_URL: process.env.CATERING_URL,
    SENTRY_DSN: process.env.SENTRY_DSN,
    POSTHOG_TOKEN: process.env.POSTHOG_TOKEN,
    POSTHOG_HOST: process.env.POSTHOG_HOST
  }
}

export default env
