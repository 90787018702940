export default {
  fields: {
    user_id: {
      readOnly: true,
    },
    agreement_id: {
      readOnly: true,
    },
    market_city_id: {
      readOnly: true,
    },
    slug: {
      readOnly: true,
    },
    token: {
      readOnly: true,
    },
    created_at: {
      readOnly: true,
      type: 'date',
    },
    updated_at: {
      readOnly: true,
    }
  },
  relationships: {
    vehicle_features: {
      type: 'vehicle_features',
    },
    photos: {
      type: 'photos'
    },
    market_city: {
      type: 'cities'
    }
  }
}
