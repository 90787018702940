export default {
  fields: {
    start_date: {
      type: 'date',
    },
    end_date: {
      type: 'date',
    }
  }
}
