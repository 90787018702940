import { format, parseISO } from 'date-fns'

export default {
  fields: {
    proposal_id: {
      readOnly: true,
    },
    event_id: {
      readOnly: true,
    },
    caterer_id: {
      readOnly: true,
    },
    service_id: {
      readOnly: true,
    },
    start_date: {
      resolve: date => (
        date ? parseISO(date) : undefined
      ),
      serialize: date => (
        date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
      )
    },
    end_date: {
      resolve: date => (
        date ? parseISO(date) : undefined
      ),
      serialize: (date, attrs) => (
        date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined
      )
    },
    caterer_subtotal: {
      readOnly: true,
    },
    caterer_fees: {
      readOnly: true,
    },
    caterer_total: {
      readOnly: true,
    },
    service_fees: {
      readOnly: true,
    },
    transaction_fees: {
      readOnly: true,
    },
    sales_tax: {
      readOnly: true,
    },
    grand_total: {
      readOnly: true,
    },
    paid_total: {
      readOnly: true,
    },
    balance: {
      readOnly: true,
    },
    client_accepted_at: {
      type: 'date',
    },
    caterer_accepted_at: {
      type: 'date',
    },
    position: {
      readOnly: true,
    },
    vendor_payout: {
      readOnly: true,
    },
    submitted_at: {
      type: 'date',
    },
    expires_at: {
      type: 'date',
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    proposal: 'proposals',
    caterer: 'caterers',
    event: 'events',
    service: 'caterer_services',
    fees: 'fees',
    package: 'booking_packages',
    overages: 'overages',
  },
}
