import React from 'react'

const theme = {
  div: null,
  label: 'label block mb-2',
  input: 'input',
  error: 'text-sm text-red-400 mt-1',
}

export default function Input(props) {
  const {
    type,
    value,
    className,
    classNames,
    onChange,
    divClassName,
    divProps,
    label,
    labelClassName,
    labelProps,
    error,
    errorProps,
    icon,
    leftIcon,
    rightIcon,
    ...inputProps
  } = props

  const styles = { ...theme, ...classNames }

  return (
    <div className={[styles.div, divClassName]} {...divProps}>
      {label && (
        <label className={[labelClassName, styles.label]} {...labelProps}>
          {label}
        </label>
      )}
      <div
        className={[
          styles.input,
          'flex items-center justify-center p-0'
        ]}
        style={{ height: "45px" }}
      >
        {leftIcon && (
          <div
            className={[
              'bg-gray-100 h-full border-r border-gray px-3',
              'flex items-center justify-center'
            ]}
          >
            {leftIcon}
          </div>
        )}
        <input
          {...inputProps}
          className={[className, "flex-1 px-3 py-2 font-light"]}
          type={type}
          value={onChange ? (value || value === 0 ? value : '') : value}
          onWheel={type === 'number' ? (e) => { 
            // For numeric inputs, disable scroll-wheel editing 
            // https://linear.app/roaming-hunger/issue/ENG-2234/rh-markup-with-random-percentages
            e.target.blur()
          } : null}
          onChange={event => {
            let val = event.target.value || null

            if (val && type === 'number') {
              val = Number(val)
            }

            if (onChange) {
              onChange(val, event)
            }
          }}
        />
        {rightIcon && (
          <div
            className={[
              'bg-gray-100 h-full border-l border-gray px-3',
              'flex items-center justify-center'
            ]}
          >
            {rightIcon}
          </div>
        )}
      </div>
      {error && (
        <div className={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
}
