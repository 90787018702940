export default {
  fields: {
    user_id: {
      readOnly: true,
    },
    subject_id: {
      readOnly: true,
    },
    subject_type: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    user: {
      type: 'users',
    },
    subject: {
      polymorphic: true,
    }
  }
}
