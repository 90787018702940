export default {
  fields: {
    booking_id: {
      readOnly: true,
    },
    caterer_id: {
      readOnly: true,
    }
  },
  relationships: {
    booking: 'bookings',
    caterer: 'caterers',
  },
}
