import React, { useState, useEffect, forwardRef } from 'react'
import { createPortal } from 'react-dom'

export const Portal = forwardRef(({ children, ...rest }, ref) => {
  const [node, setNode] = useState(null)

  useEffect(() => {
    setNode(document.body)
  }, [])

  return node
    ? createPortal(
        <div ref={ref} {...rest}>
          {children}
        </div>,
        node
      )
    : null
})
