export default {
  fields: {
    uuid: {
      readOnly: true,
    },
    lead_id: {
      readOnly: true,
    },
    user_id: {
      readOnly: true,
    },
    experience_manager_id: {
      readOnly: true,
    },
    vendor_coordinator_id: {
      readOnly: true,
    },
    agreement_id: {
      readOnly: true,
    },
    contact_id: {
      readOnly: true,
    },
    caterer_id: {
      readOnly: true,
    },
    account_id: {
      readOnly: true,
    },
    token: {
      readOnly: true,
    },
    caterer_subtotal: {
      readOnly: true,
    },
    caterer_fees: {
      readOnly: true,
    },
    caterer_total: {
      readOnly: true,
    },
    service_fees: {
      readOnly: true,
    },
    instant_book: {
      readOnly: true,
    },
    transaction_fees: {
      readOnly: true,
    },
    sales_tax: {
      readOnly: true,
    },
    grand_total: {
      readOnly: true,
    },
    paid_total: {
      readOnly: true,
    },
    date_paid: {
      type: 'date',
    },
    balance: {
      readOnly: true,
    },
    submitted_at: {
      type: 'date',
    },
    expires_at: {
      type: 'date',
    },
    total_bookings: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    account: {
      type: 'accounts'
    },
    caterer: {
      type: 'caterers',
    },
    experience_manager: {
      type: 'users',
    },
    vendor_coordinator: {
      type: 'users',
    },
    contact: {
      type: 'contacts',
    },
    lead: {
      type: 'leads',
    },
    events: {
      type: 'events',
    },
    bookings: {
      type: 'bookings',
    },
    payments: {
      type: 'payments',
    },
    client_receipt: {
      type: 'client_receipt'
    }
  },
}
