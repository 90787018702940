export default {
  fields: {
    city_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    city: 'cities',
    events: {
      type: 'events',
    },
  }
}
