import React, { useState } from 'react'
import Modal from './Modal'
import { Info } from 'react-feather'

export default function InfoModal(props) {
  const {
    isOpen = true,
    closeModal = () => isOpen = false,
    title = 'Informational',
    message,
  } = props

  return (
    <Modal
      isOpen={isOpen}
      className="p-8"
      closeModal={closeModal}
    >
      <div className="">
        <div className={[
          'w-12 h-12 rounded-full flex items-center justify-center',
          'bg-blue-200 text-blue-600 mx-auto mb-3'
        ]}>
          <Info />
        </div>
        <div className="leading-snug text-center mb-6">
          <h3 className="font-bold text-2xl">
            {title}
          </h3>
          <p className="text-gray-700 font-light">
            {message}
          </p>
        </div>
        {props.children}
      </div>
      <div className="grid grid-cols-3 gap-6">
        <button
          children="Close"
          onClick={closeModal}
          className={[
            'btn btn-blue btn-md col-start-2'
          ]}
        />
      </div>
    </Modal>
  )
}
