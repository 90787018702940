import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { ChevronDown, Menu, X, Settings, User } from 'react-feather'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useSession } from 'app/session'
import { Portal } from 'lib/portal'
import logo from 'assets/svg/logo-with-circle.svg'
import styles from './Header.module.css'

export default function Header() {
  const session = useSession()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.open)
    }
    return () => {
      if (isOpen) {
        document.body.classList.remove(styles.open)
      }
    }
  }, [isOpen])

  return (
    <header className={[
      styles.header,
      'md:relative z-50 flex flex-col w-full top-0 bg-white shadow-sm',
    ]}>
      <div className={[
        styles.nav,
        'container flex items-center justify-center md:justify-between'
      ]}>
        <Link to="/" className="md:mr-8">
          <img src={logo} width={56} />
        </Link>

        <div className="hidden md:flex flex-1 h-full items-center justify-between">
          <div className="flex h-full">
            <NavLink
              to="/leads"
              children="Leads"
              className={styles.navLink}
            />
            <NavLink
              to="/proposals"
              children="Proposals"
              className={styles.navLink}
            />
            <NavLink
              to="/events"
              children="Events"
              className={styles.navLink}
            />
            <NavLink
              to="/caterers"
              children="Caterers"
              className={styles.navLink}
            />
          </div>
          <div className="flex h-full items-center">
            <NavDropdown />
            <UserDropdown user={session.user} />
          </div>
        </div>

        <button
          type="button"
          className={[
            'p-2 absolute right-0 mr-3 rounded-md focus:outline-none',
            'hover:bg-gray-200 focus:bg-gray-200 md:hidden'
          ]}
          onClick={() => {
            setOpen(open => !open)
          }}
          children={
            isOpen ? (
              <X className="text-gray-900" size={26} />
            ) : (
              <Menu className="text-gray-900" size={26} />
            )
          }
        />
      </div>

      <div className={[styles.menu, 'flex-1 font-light overflow-auto']}>
        <div className="container border-t border-gray-200 py-3">
          <Link to={`/users/${session.id}`} children="My Account" />

        </div>
        <div className="container py-3 border-t border-gray-200">
          <NavLink to="/leads" children="Leads" />
          <NavLink to="/events" children="Events" />
          <NavLink to="/caterers" children="Caterers" />
        </div>
        <div className="container border-t border-gray-200 py-3">
          <Link to="/logout" children="Log Out" />
        </div>
      </div>

    </header>
  )
}

export function useDropDown() {
  const ref = useRef()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const onOutsideClick = ({ target }) => {
      if (
        target.tagName === 'A' ||
        ref.current &&
        ref.current !== target &&
        !ref.current.contains(target)
      ) {
        setOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('click', onOutsideClick)
    }

    return () => {
      if (isOpen) {
        document.removeEventListener('click', onOutsideClick)
      }
    }
  }, [isOpen])

  return {
    ref,
    isOpen,
    setOpen,
  }
}

function NavDropdown() {
  const { ref, isOpen, setOpen } = useDropDown()
  const vendor_bills_enabled = useFeatureFlagEnabled('vendor_bill_feature_flag')

  // Allow the user to manually turn on vendor bills
  const queryParams = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return {
      vendor_bills_enabled: params.get('vendor_bills_enabled'),
    }
  }, [location])

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          setOpen(true)
        }}
        className={[
          'btn text-gray-800 rounded-full h-10 w-10 flex items-center justify-center',
          'mr-2 hover:bg-gray-200'
        ]}
        children={
          <Settings />
        }
      />
      <Portal>
        <div
          ref={ref}
          className={[
            styles.navDropdown,
            isOpen && styles.open,
            'hidden md:block',
          ]}
        >
          <div className="container py-6">
            <div className="grid grid-cols-3 lg:grid-cols-6">
              <ul>
                <li><h3>Catering</h3></li>
                <li><Link to="/accounts">Accounts</Link></li>
                <li><Link to="/bookings">Bookings</Link></li>
                <li><Link to="/documents">Documents</Link></li>
                <li><Link to="/territories">Territories</Link></li>
                <li><Link to="/catering-minimums">Minimums</Link></li>
                <li><Link to="/occasions">Occasions</Link></li>
                <li><Link to="/caterer-submissions">Submissions</Link></li>
                <li><Link to="/agreements">Agreements</Link></li>
                {(vendor_bills_enabled || queryParams.vendor_bills_enabled) && (
                  <li><Link to="/bills">Vendor Payout Approval</Link></li>
                )}
              </ul>
              <ul>
                <li><h3>Content</h3></li>
                <li><Link to="/pages">Pages</Link></li>
                <li><Link to="/tags">Tags</Link></li>
                <li><Link to="/good-fors">Good Fors</Link></li>
                <li><Link to="/dietary-tags">Dietary Tags</Link></li>
                <li><Link to="/featured-clients">Featured Clients</Link></li>
                <li><Link to="/feature-releases">Feature Releases</Link></li>
                <li><Link to="/press-highlights">Press Highlights</Link></li>
              </ul>
              <ul>
                <li><h3>Places</h3></li>
                <li><Link to="/countries">Countries</Link></li>
                <li><Link to="/regions">Regions</Link></li>
                <li><Link to="/cities">Cities</Link></li>
                <li><Link to="/landmarks">Landmarks</Link></li>
                <li><Link to="/nearest-city">Nearest City</Link></li>
              </ul>
              <div className="">
                <ul>
                  <li><h3>Agency</h3></li>
                  <li><Link to="/agency-leads">Leads</Link></li>
                  <li><Link to="/case-studies">Case Studies</Link></li>
                </ul>
                <ul>
                  <li><h3>Reports</h3></li>
                  <li><Link to="/accounting">Accounting Export</Link></li>
                  <li><Link to="/performance">Performance</Link></li>
                  <li><Link to="/reports/custom">Custom Reports</Link></li>
                </ul>
              </div>
              <div className="">
                <ul>
                  <li><h3>Marketplace</h3></li>
                  <li><Link to="/marketplace/listings">Listings</Link></li>
                  <li><Link to="/marketplace/inquiries">Inquiries</Link></li>
                  <li><Link to="/marketplace/leads">Leads</Link></li>
                </ul>
                <ul>
                  <li><h3>Promotions</h3></li>
                  <li><Link to="/promotions">Promotions</Link></li>
                  <li><Link to="/promotion-surveys">Promotion Surveys</Link></li>
                  <li><Link to="/promotion-accounts">Promotion Accounts</Link></li>
                </ul>
              </div>

              <ul>
                <li><h3>Administration</h3></li>
                <li><Link to="/users">Users</Link></li>
                <li><Link to="/admins">Admins</Link></li>
                <li><Link to="/settings">Settings</Link></li>
                <li><Link to="/reviews">Reviews</Link></li>
                <li><Link to="/review-tags">Review Tags</Link></li>
                <li><Link to="/audits">History Log</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </Portal>
    </div>
  )
}

function UserDropdown({ user }) {
  const { ref, isOpen, setOpen } = useDropDown()

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => {
          setOpen(true)
        }}
        className={[
          'btn text-gray-800 rounded-full h-10 w-10 flex items-center justify-center',
          'hover:bg-gray-200 focus:bg-gray-200'
        ]}
        children={
          <User />
        }
      />
      <div ref={ref} className={[styles.userDropDown, isOpen && styles.open]}>
        <div className="py-4 px-6 leading-tight font-normal text-xl">
          <div className="">{user.full_name}</div>
          <div className="text-sm text-gray-700">
            {user.email}
          </div>
        </div>
        <div className="border-t border-gray-200 py-3">
          <Link to={`/users/${user.id}`}>My Account</Link>
          <Link to="/logout">Sign Out</Link>
        </div>
      </div>
    </div>
  )
}
