import './theme/styles'

import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import LoginPage from './login/LoginPage'
import LoadingScreen from './theme/LoadingScreen'
import { NotificationProvider } from 'app/theme/Notification'
import { useCreateSession, SessionContext } from './session'
import routes from './routes'

export default function App() {
  const session = useCreateSession()

  return (
    <SessionContext.Provider value={session}>
      <NotificationProvider>
        <Routes session={session} />
      </NotificationProvider>
    </SessionContext.Provider>
  )
}

function Routes({ session }) {
  const location = useLocation()

  useEffect(() => {
    if (!location.state || location.state.scrollTop !== false) {
      window.scrollTo(0, 0)
    }
  }, [location.pathname, location.search])

  return (
    <Switch>
      {routes.gated.map(route => (
        <Route
          key={route.path}
          exact={route.exact !== false}
          path={route.path}
          children={props =>
            session.isLoading ? (
              <LoadingScreen />
            ) : !session.isLoggedIn ? (
              <LoginPage {...props} session={session} />
            ) : (
              <route.component {...props} session={session} />
            )
          }
        />
      ))}
      {routes.ungated.map(route => (
        <Route
          key={route.path}
          exact={true}
          path={route.path}
          children={props => <route.component {...props} session={session} />}
        />
      ))}
    </Switch>
  )
}
