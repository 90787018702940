export default {
  types: {
    truck: 'Truck',
    trailer: 'Trailer',
    cart: 'Cart',
    popup: 'Pop-Up',
    traditional: 'Traditional',
    dropoff: 'Drop-Off',
  }
}
