import React, { useState } from 'react'
import Modal from './Modal'
import { Checkbox, Form, Submit } from 'app/theme/forms'
import { RefreshCw } from 'react-feather'

export default function SwapModal(props) {
  const {
    isOpen = true,
    onCancel = () => {},
    closeModal = onCancel,
    onSubmit,
    disable = false,
    formName = 'swapModalForm',
    strict = false,
    title = 'Confirmation',
    message = 'Please confirm you want to take this action',
  } = props

  const [confirmed, setConfirmed] = useState(false)
  const isDisabled = (strict && !confirmed) || disable
  
  const onSubmitHandler = (event) => {
    event.preventDefault()
    onSubmit(event.target)
  }

  return (
    <Modal
      isOpen={isOpen}
      className="p-8"
      closeModal={closeModal}
    >
      <Form
        id={formName} 
        className=""
        method="post"
        onSubmit={onSubmitHandler}
        >
        <div>
        <div className={[
          'w-12 h-12 rounded-full flex items-center justify-center',
          'bg-red-200 text-red-600 mx-auto mb-3'
        ]}>
          <RefreshCw />
        </div>
        <div className="leading-snug text-center mb-6">
          <h3 className="font-bold text-2xl">
            {title}
          </h3>
          <p className="text-gray-700 font-light">
            {message}
          </p>
        </div>
        {props.children}
        {strict && (
          <div className="m-6 flex justify-center">
            <Checkbox
              name={formName + "Confirm"}
              label={<span className="text-gray-800 font-light text-base">Yes, I want to proceed</span>}
              checked={confirmed}
              onChange={checked => {
                setConfirmed(checked)
              }}
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-6">
        <button
          children="Cancel"
          className="btn btn-gray btn-md"
          onClick={onCancel}
        />
        <Submit
          children="Confirm"
          disabled={isDisabled}
          className={[
            'btn btn-red btn-md w-full',
            isDisabled && 'opacity-50 pointer-events-none',
          ]}
        />
        </div>
      </Form>
    </Modal>
  )
}
