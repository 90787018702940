import React from 'react'
import { Modal as BaseModal } from 'lib/modal'
import { X } from 'react-feather'
import Spinner from './Spinner'

export default function Modal(props) {
  const {
    isLoading,
    className,
    children,
    closeModal,
    ...rest
  } = props

  if (isLoading) {
    return (
      <BaseModal
        rootSelector="#modals"
        closeModal={closeModal}
        children={<Spinner className="block mx-auto" />}
        {...rest}
      />
    )
  }

  return (
    <BaseModal
      rootSelector="#modals"
      closeModal={closeModal}
      className={[
        className,
        'relative bg-white rounded shadow w-full max-w-lg m-3',
      ]}
      {...rest}
    >
      {closeModal && (
        <button
          type="button"
          className={[
            'btn p-2 rounded-full bg-gray-200 hover:bg-gray-400',
            'absolute z-40 right-0 top-0 -mr-3 -mt-3 shadow',
          ]}
          children={
            <X size={20} />
          }
          onClick={() => {
            closeModal()
          }}
        />
      )}
      {children}
    </BaseModal>
  )
}
