import React, { useState, useMemo, useEffect } from 'react'
import { X, Search } from 'react-feather'

export default function AutoComplete(props) {
  const {
    value,
    label,
    error,
    placeholder = 'Search',
    useInitialOnMount = false,
    getInitialResults = () => { return null },
    onChange = () => {},
    onSelect = onChange,
    getResults = () => {},
    getLabel = () => {}
  } = props

  const [term, setTerm] = useState('')
  const [results, setResults] = useState(null)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    useInitialOnMount && fetchInitialResults();
  }, [])

  useEffect(() => {
    if (value) {
      setTerm(getLabel(value))
    } else {
      setTerm('')
    }
  }, [value])

  async function fetchInitialResults() {
    const initial = await getInitialResults()
    setResults(initial)
  }

  const handleInputChange = async event => {
    const val = event.target.value
    setTerm(val)

    if (val) {
      const results = await getResults(val)
      setResults(results || null)
    } else {
      onChange(null)
      setResults(null)
    }
  }

  return (
    <div className="relative">
      {label && (
        <label className="label block mb-2">
          {label}
        </label>
      )}
      <div className="relative flex items-center">
        <Search
          size={18}
          className="absolute left-0 ml-3 text-gray-500"
        />
        <input
          type="text"
          placeholder={placeholder}
          value={term}
          onChange={handleInputChange}
          onFocus={() => {
            if (!results) {
              fetchInitialResults()
            }
            setFocused(true)
          }}
          onBlur={() => {
            setFocused(false)
            setResults(null)

            if (!value) {
              setTerm('')
            } else if (getLabel(value) !== term) {
              setTerm(getLabel(value))
            }
          }}
          className={[
            'input pl-10'
          ]}
        />
      </div>
      {error && (
        <div className="text-sm text-red-400 mt-1">
          {error}
        </div>
      )}
      {focused && results ? (
        <div
          className={[
            'bg-white shadow-xl rounded-sm absolute z-50 left-0 right-0 p-2',
            '-mx-1 border border-gray-500 grid gap-1'
          ]}
        >
          {!results.length && (
            <p className="text-gray-700 text-sm font-light p-1">
              No results found.
            </p>
          )}
          {results.map(rec => (
            <div
              key={rec.id}
              onMouseDown={() => {
                onChange(rec)
                setResults(null)
              }}
              className={[
                'cursor-pointer py-1 px-4 -mx-2 text-sm',
                'hover:bg-gray-100 rounded',
              ]}
            >
              {getLabel(rec)}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
