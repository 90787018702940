import React, { useState } from 'react'
import { parse as parseFn, format as formatFn, set as setFn } from 'date-fns'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import TimePicker from './TimePicker'
import Select from 'app/theme/forms/Select'
import './DatePicker.css'

function parseDate(str, format, locale) {
  const parsed = parseFn(str, format, new Date(), { locale })
  return DateUtils.isDate(parsed) ? parsed : undefined
}

function formatDate(date, format, locale) {
  return formatFn(date, format, { locale })
}

const currentDate = new Date()
const currentYear = currentDate.getFullYear()
const fromMonth = new Date(currentYear - 10, 0)
const toMonth = new Date(currentYear + 10, 11)

export default function DatePicker(props) {
  let {
    date,
    value = date,
    onChange = () => {},
    placeholder,
    className,
    timePicker,
    disableBefore,
    useYearMonthDropdown = true,
    ...rest
  } = props

  const [month, setMonth] = useState(currentDate)

  if (disableBefore === 'today') {
    disableBefore = new Date()
  }

  if (placeholder && placeholder instanceof Date) {
    placeholder = formatFn(placeholder, timePicker ? 'MM/dd/yyyy, hh:mm a' : 'MM/dd/yyyy')
  }

  return (
    <DayPickerInput
      value={value}
      parseDate={parseDate}
      formatDate={formatDate}
      placeholder={placeholder || (timePicker ? 'mm/dd/yyyy, hh:mm' : 'mm/dd/yyyy')}
      format={timePicker ? 'MM/dd/yyyy, hh:mm a' : 'MM/dd/yyyy'}
      dayPickerProps={{
        disabledDays: disableBefore ? { before: disableBefore } : null,
        month: month,
        captionElement: ({ date, localeUtils }) => (
          useYearMonthDropdown ? (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={month => setMonth(month)}
            />
          ) : (
            <div className="DayPicker-Caption">
              {localeUtils.formatMonthTitle(date)}
            </div>
          )
        )
      }}
      inputProps={{
        readOnly: true,
        className: className,
        onKeyDown: event => {
          if (event.key === 'Enter') {
            event.target.blur()
          }
        }
      }}
      onDayChange={day => {
        if (!day) {
          onChange(null)
        } else if (date) {
          onChange(setFn(day, {
            hours: date.getHours(),
            minutes: date.getMinutes()
          }))
        } else {
          onChange(day)
        }
      }}
      overlayComponent={timePicker ? (props => (
        <TimeOverlay date={date} {...props} onChange={onChange} />
      )) : undefined}
      {...rest}
    />
  )
}

function YearMonthForm(props) {
  const { date, localeUtils, onChange } = props

  const years = []
  const months = localeUtils.getMonths()

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const handleMonthChange = month => {
    onChange(new Date(date.getFullYear(), month))
  }

  const handleYearChange = year => {
    onChange(new Date(year, date.getMonth()))
  }

  return (
    <div className="DayPicker-Caption">
      <div className="flex w-full">
        <Select
          className="flex-1 border p-1 pr-5 text-sm font-bold"
          onChange={handleMonthChange}
          value={date.getMonth()}
          options={months.map((month, index) => ({
            label: month,
            value: index
          }))}
        />

        <Select
          className="flex-1 border ml-1 p-1 pr-5 text-sm font-bold"
          onChange={handleYearChange}
          value={date.getFullYear()}
          options={years.map((year) => ({
            label: year,
            value: year
          }))}
        />
      </div>
    </div>
  )
}

function TimeOverlay(props) {
  const {
    date,
    onChange,
    children,
    classNames,
    selectedDay,
    month,
    ...rest
  } = props

  return (
    <div {...rest} className={classNames.overlayWrapper}>
      <div className={classNames.overlay}>
        {children}
        <div className="border-t border-gray-300 pt-5 mx-5 mb-5">
          <TimePicker
            date={date}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  )
}
