export default {
  fields: {
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
    parent_id: {
      readOnly: true,
    },
  },
  relationships: {
    photos: {
      type: 'photos'
    },
    photo: {
      type: 'photos'
    },
    parent: {
      type: 'tags'
    }
  }
}
