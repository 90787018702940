export default {
  fields: {
    invoice_id: {
      readOnly: true,
    },
    proposal_id: {
      readOnly: true,
    },
    date_paid: {
      type: 'date',
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    proposal: {
      type: 'proposals',
    },
    items: {
      type: 'payment_items',
    }
  },
}
