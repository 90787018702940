import React from 'react'
import Dropdown from './Dropdown'

export default function ButtonDropdown(props) {
  const {
    children,
    text,
    icon,
    items = [],
    onItemClick = () => {},
    content,
    contentClassName,
    itemClassName,
    render,
    ...toggleProps
  } = props

  return (
    <Dropdown>
      {(icon || text) ? (
        <Dropdown.Toggle {...toggleProps}>
          {icon}
          {text}
          {children}
        </Dropdown.Toggle>
      ) : (
        <Dropdown.Toggle
          children={children}
          {...toggleProps}
        />
      )}
      <Dropdown.Content
        className={[
          'bg-white shadow rounded py-2',
          'mt-1 font-light',
          'border border-gray-300',
          contentClassName,
        ]}
      >
        {content}
        {items.map((item, index) => (
          <Dropdown.Item
            key={item.value || index}
            children={item.label || item.children}
            onClick={() => {
              (item.onClick || onItemClick)(item.value)
            }}
            className={[
              'btn block w-full text-left px-4 hover:bg-gray-200',
              'text-left font-light leading-loose',
              itemClassName,
            ]}
          />
        ))}
      </Dropdown.Content>
    </Dropdown>
  )
}
