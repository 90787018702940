export default {
  fields: {
    listing_id: {
      readOnly: true
    },
    user_id: {
      readOnly: true
    },
    created_at: {
      readOnly: true,
      type: 'date',
    },
    updated_at: {
      readOnly: true
    }
  },
  relationships: {
    listing: {
      type: 'market_listings',
    }
  }
}
