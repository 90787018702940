import React from 'react'
import { hydrate } from 'react-dom'
import { loadableReady } from '@loadable/component'
import { BrowserRouter } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { CookieProvider } from 'lib/cookies'
import { ApiProvider, ApiClient } from 'jsonapi-react'
import App from 'app/App'
import createClient from './app/createClient'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'

import env from 'env'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.APP_ENV,
    release: env.APP_VERSION,
  })
}

if (typeof window !== 'undefined') {
  posthog.init(
    env.POSTHOG_TOKEN, 
    {
      api_host: env.POSTHOG_HOST,
      autocapture: false,
      disable_session_recording: true,
      capture_pageview: false,
    }
  )
}


const state = window.__APP_STATE__ || {}

const client = createClient({ url: state.env.API_URL })
client.hydrate(state.api)

const Root = (
  <Sentry.ErrorBoundary showDialog>
    <CookieProvider value={new Cookies()}>
      <ApiProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApiProvider>
    </CookieProvider>
  </Sentry.ErrorBoundary>
)

loadableReady(() => {
  hydrate(Root, document.getElementById('root'))
})

if (module.hot) {
  module.hot.accept()
}
