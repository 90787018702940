import React from 'react'

export default function SectionHeader(props) {
  const { title, icon, className, actions, border, ...rest } = props

  return (
    <div {...rest} className={[
      'flex items-center justify-between',
      border !== false && 'border-b border-dashed border-gray-300 pb-4',
      className,
    ]}>
      <h1 className="flex items-center font-platform text-2xl">
        {icon}
        {title}
      </h1>
      {actions}
    </div>
  )
}
