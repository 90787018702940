export default {
  fields: {
    caterer_id: {
      readOnly: true,
    },
    booking_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
    review_tags: {
      readOnly: true,
    }
  },
  relationships: {
    caterer: 'caterers',
    booking: 'bookings',
    event: 'events'
  }
}
