import { ApiClient } from 'jsonapi-react'
import { GraphitiPlugin } from 'jsonapi-react-plugin-graphiti'

import accounting_exports from './accounting_exports/schema'
import accounts from './accounts/schema'
import admins from './admins/schema'
import agreements from './agreements/schema'
import agency_leads from './agency-leads/schema'
import bills from './bills/schema'
import bookings from './bookings/schema'
import booking_packages from './booking-packages/schema'
import booking_package_items from './booking-package-items/schema'
import booking_package_categories from './booking-package-categories/schema'
import events from './events/schema'
import catering_minimums from './catering-minimums/schema'
import caterer_submissions from './caterer-submissions/schema'
import case_studies from './case-studies/schema'
import cities from './cities/schema'
import contacts from './contacts/schema'
import calendar_events from './calendar-events/schema'
import caterers from './caterers/schema'
import caterer_services from './caterer-services/schema'
import caterer_stories from './caterer-stories/schema'
import caterer_settings from './caterer-settings/schema'
import caterer_agreements from './caterer-agreements/schema'
import client_receipts from './client_receipts/schema'
import countries from './countries/schema'
import documents from './documents/schema'
import dietary_tags from './dietary-tags/schema'
import good_fors from './good-fors/schema'
import feature_releases from './feature-releases/schema'
import featured_clients from './featured-clients/schema'
import packages from './packages/schema'
import package_items from './package-items/schema'
import package_categories from './package-categories/schema'
import proposals from './proposals/schema'
import tags from './tags/schema'
import market_inquiries from './market-inquiries/schema'
import market_leads from './market-leads/schema'
import market_listings from './market-listings/schema'
import menu_categories from './menu-categories/schema'
import menu_items from './menu-items/schema'
import memberships from './memberships/schema'
import messages from './messages/schema'
import leads from './leads/schema'
import locations from './locations/schema'
import reviews from './reviews/schema'
import review_tags from './review-tags/schema'
import photos from './photos/schema'
import notes from './notes/schema'
import users from './users/schema'
import landmarks from './landmarks/schema'
import press_highlights from './press-highlights/schema'
import promotions from './promotions/schema'
import promotion_surveys from './promotion-surveys/schema'
import promotion_accounts from './promotion-accounts/schema'
import regions from './regions/schema'
import territories from './territories/schema'
import occasions from './occasions/schema'
import fees from './fees/schema'
import overages from './overages/schema'
import payments from './payments/schema'
import payment_items from './payment-items/schema'
import pages from './pages/schema'
import story_tags from './story-tags/schema'

export default function createClient(options) {
  return new ApiClient({
    ...options,
    cacheTime: 60 * 10,
    plugins: [new GraphitiPlugin()],
    schema: {
      accounting_exports,
      accounts,
      admins,
      agreements,
      agency_leads,
      bills,
      bookings,
      booking_packages,
      booking_package_items,
      booking_package_categories,
      cities,
      catering_minimums,
      caterer_submissions,
      caterer_agreements,
      case_studies,
      client_receipts,
      countries,
      calendar_events,
      caterers,
      caterer_services,
      caterer_stories,
      caterer_settings,
      good_fors,
      documents,
      dietary_tags,
      events,
      fees,
      feature_releases,
      featured_clients,
      packages,
      package_items,
      package_categories,
      promotions,
      promotion_surveys,
      promotion_accounts,
      contacts,
      proposals,
      tags,
      market_inquiries,
      market_listings,
      market_leads,
      menu_categories,
      menu_items,
      memberships,
      messages,
      leads,
      locations,
      landmarks,
      reviews,
      review_tags,
      photos,
      notes,
      users,
      press_highlights,
      regions,
      territories,
      occasions,
      overages,
      payments,
      payment_items,
      pages,
      story_tags,

      caterer_cities: {
        relationships: {
          caterer: 'caterers',
          city: 'cities',
        },
      },
      caterer_tags: {
        relationships: {
          tag: 'tags',
          caterer: 'caterers',
        },
      },
      caterer_good_fors: {
        relationships: {
          good_for: 'good_fors',
          caterer: 'caterers',
        },
      },
      permissions: {
        relationships: {
          admin: 'admins',
          department: 'departments'
        }
      }
    },
  })
}
