import React, { useState, useEffect, useRef } from 'react'
import { X, ChevronDown } from 'react-feather'


export default function MultiSelect(props) {
  const {
    options = [],
    selected = [],
    onChange,
  } = props

  const selectedOptions = selected.map(option => (typeof option === 'number') ? option.toString() : option)

  const [focused, setFocused] = useState(false)
  const [hovered, setHovered] = useState()

  const wrapperRef = useRef()
  const inputRef = useRef()
  const optionsRef = useRef()

  useEffect(() => {
    if (focused) {
      inputRef.current.focus()
    } else {
      if (hovered) {
        setHovered(null)
      }
    }
  }, [focused])

  useEffect(() => {
    const onClick = event => {
      if (!wrapperRef.current.contains(event.target)) {
        setFocused(false)
      }
    }
    if (focused) {
      document.addEventListener('click', onClick)
      return () => {
        document.removeEventListener('click', onClick)
      }
    }
  }, [focused])


  return (
    <div
      ref={wrapperRef}
      className={[
        'relative input bg-white p-0',
        focused ? 'border-blue shadow-outline' : null,
      ]}
      onClick={e => {
        if (!optionsRef.current || !optionsRef.current.contains(e.target)) {
          setFocused(prev => !prev)
        }
      }}
    >
      <div className="flex items-center">
        <div
          className={[
            'flex-1 flex flex-wrap items-center overflow-hidden',
            selectedOptions.length && 'pt-1 pb-2 pl-1',
          ]}
        >
          {selectedOptions.map(selection => (
            <div
              key={selection}
              className={[
                'flex items-center bg-blue rounded-sm text-white',
                'pl-3 pr-1 mt-1 ml-1',
              ]}
            >
              <span>
                {options.find(option => option.value === selection).label}
              </span>
              <button
                type="button"
                children={<X size={14} />}
                className="btn ml-1 p-1 rounded-full hover:bg-blue-600"
                onMouseUp={e => {
                  e.stopPropagation()
                  onChange(selectedOptions.filter(value => value !== selection))
                  inputRef.current.focus()
                }}
              />
            </div>
          ))}
          <div className="flex flex-1">
            <input
              placeholder='Select Tiers'
              ref={inputRef}
              disabled
              className=
                'w-full border-0 bg-transparent focus:outline-none font-light px-3 py-2'
            />
          </div>
        </div>
        <div className="px-2">
          <ChevronDown size={20} className="text-gray-600 hover:text-gray-800" />
        </div>
      </div>
      {focused && (
        <div
          ref={optionsRef}
          style={{
            maxHeight: 258,
            left: -1,
            right: -1,
          }}
          className={[
            'bg-white shadow-xl rounded-sm absolute z-50 py-1 mt-2',
            'border border-gray-300 grid text-lg overflow-auto'
          ]}
        >
          {options.map((option, index) => {
            const isSelected = selectedOptions.indexOf(option.value) >= 0
            const isHovered = ((!hovered && index === 0) || (hovered && hovered.value === option.value))

            return (
              <div key={option.value}>
                <div
                  children={option.label}
                  className={[
                    'px-3 leading-loose cursor-default',
                    isSelected ? 'bg-green-200' : (isHovered ? 'bg-blue-200' : null),
                  ]}
                  onMouseUp={() => {
                    if (isSelected) {
                      onChange(selectedOptions.filter(value => value !== option.value))
                    } else {
                      onChange(selectedOptions.concat(option.value))
                    }
                    inputRef.current.focus()
                  }}
                  onMouseOver={() => {
                    setHovered(option)
                  }}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )

}
