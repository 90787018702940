import React from 'react'
import { DatePicker } from 'app/theme/date-picker'
import { Calendar } from 'react-feather'

const theme = {
  div: null,
  label: 'label block mb-2',
  input: 'input cursor-pointer',
  error: 'text-sm text-red-400 mt-1',
}

export default function Datetime(props) {
  const {
    value,
    timePicker = true,
    className,
    classNames,
    divProps,
    label,
    labelClassName,
    labelProps,
    error,
    errorProps,
    ...inputProps
  } = props

  const styles = { ...theme, ...classNames }

  return (
    <div className={styles.div} {...divProps}>
      {label && (
        <label className={[styles.label, labelClassName]} {...labelProps}>
          {label}
        </label>
      )}
      <div className="relative flex items-center">
        <Calendar
          size={20}
          className="absolute right-0 mr-3 text-gray-600 pointer-events-none"
        />
        <div className="flex-1">
          <DatePicker
            {...inputProps}
            timePicker={timePicker}
            className={[styles.input, 'pr-10']}
            date={value}
          />
        </div>
      </div>
      {error && (
        <div className={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
}
