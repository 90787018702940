export default {
  fields: {
    account_id: {
      readOnly: true,
    },
    caterer_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    leads: {
      type: 'leads',
    },
    proposals: {
      type: 'proposals',
    },
    account: {
      type: 'accounts',
    },
    caterer: {
      type: 'caterers',
    },
  },
}
