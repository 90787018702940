import React from 'react'

export default function Main(props) {
  return (
    <main
      children={props.children}
      className={[
        props.className,
        'flex-1',
      ]}
    />
  )
}
