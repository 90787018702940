import React from 'react'
import { ErrorBoundary as SentryBoundary } from '@sentry/react'
import { useLocation } from 'react-router-dom'

export function Fallback() {
  return (
    <div className="w-full py-10 text-center">
      <h1 className="font-platform text-3xl leading-none mb-6">
        Oops, looks like something is broken.
      </h1>
      <p className="font-light">
        <span>Please try reloading the page</span>
      </p>
    </div>
  )
}

export default function ErrorBoundary(props) {
  const location = useLocation()

  return (
    <SentryBoundary
      key={location.pathname}
      fallback={Fallback}
      children={props.children || null}
    />
  )
}