export default {
  fields: {
    user_id: {
      readOnly: true,
    },
    vendor_coordinator_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    user: 'users',
    vendor_coordinator: 'users',
  }
}
