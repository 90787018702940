const payableTypes = {
  'Booking': 'bookings',
  'Overage': 'overages',
}

export default {
  fields: {
    payment_id: {
      readOnly: true,
    },
    payable_id: {
      readOnly: true,
    },
    payable_type: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    payment: {
      type: 'payments',
    },
    payable: {
      polymorphic: true,
      getType: attrs => {
        return payableTypes[attrs.payable_type]
      }
    },
  }
}
