export default {
  fields: {
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    caterer: 'caterers',
    categories: 'package_categories',
    items: 'package_items',
    photo: 'photo',
  },
}
