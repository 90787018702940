import { useMemo } from 'react'
import { parse, format, set as setTime } from 'date-fns'

const parseTime = (hour, minutes, meridiem, date) => (
  parse(`${hour}:${minutes} ${meridiem}`, 'h:m a', date || new Date())
)

export default function useTimeState(date, onChange) {
  const [hour, minutes, meridiem] = useMemo(() => {
    if (!date) {
      return [0, 0, 'PM']
    }

    let hour = date.getHours()
    let mins = (Math.round(date.getMinutes()/15) * 15) % 60
    let meri = hour > 11 ? 'PM' : 'AM'

    if (hour === 0) {
      hour = 12
    } else if (hour > 12) {
      hour = hour - 12
    }

    return [hour, mins, meri]
  }, [date])

  const setHour = value => {
    const next = parseTime(value, minutes, meridiem, date)
    onChange(next)
  }

  const setMinutes = value => {
    const next = parseTime(hour, value, meridiem, date)
    onChange(next)
  }

  const setMeridiem = value => {
    const next = parseTime(hour, minutes, value, date)
    onChange(next)
  }

  return {
    hour,
    minutes,
    meridiem,
    setHour,
    setMinutes,
    setMeridiem,
  }
}
